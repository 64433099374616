import * as React from "react"
import Layout from "../components/layout"

export default function IndexPage() {
  return (
    <Layout>
      <div class="flex flex-col lg:flex-row gap-0 lg:gap-6 mx-8 lg:mx-auto py-16 xl:w-[1440px]">
        <div class="basis-6/12">
          <div class="mb-6 text-2xl">APERITIF</div>
          <div class="flex flex-row mb-20 lg:mb-14">
            <div class="basis-9/12 lg:basis-10/12 border-b pb-4">
              Campari / Martini Bianco / Dry Martini / Martini Rosso
            </div>
            <div class="basis-3/12 lg:basis-1/12 text-right border-b pb-4">
              79
            </div>
          </div>

          {/* Meny 1 */}
          <div class="mb-6">MENY 1</div>
          <div class="flex flex-row mb-4">
            <div class="basis-5/6">
              <b>Vitlöksbröd </b>
              <br />
              med chiliaioli & grönsallad
            </div>
          </div>
          <div class="flex flex-row mb-4">
            <div class="basis-9/12 lg:basis-10/12 border-b pb-4">
              <b>Fläskfilé Black & White</b>
              <br />
              fläskfilémedaljonger med bearnaisesås & rödvinssås
            </div>
            <div class="basis-3/12 lg:basis-1/12 text-right border-b pb-4">
              <br />
              279
            </div>
          </div>

          {/* Meny 2 */}
          <div class="mb-6">MENY 2</div>
          <div className="flex flex-row mb-4">
            <div className="basis-5/6">
              <b>Toast Skagen</b>
              <br />
              med räkor, crème fraiche & majonnäs toppat med kräftstjärtar
            </div>
          </div>
          <div className="flex flex-row mb-4">
            <div className="basis-9/12 lg:basis-10/12  border-b pb-4">
              <b>Oxfilé Oscar</b>
              <br />
              med bearnaisesås, grön sparris & kräftstjärtar
            </div>
            <div className="basis-3/12 lg:basis-1/12 text-right border-b pb-4">
              <br />
              379
            </div>
          </div>

          {/* KOCKENS VAL */}
          <div class="mb-6">KOCKENS VAL</div>
          <div class="flex flex-row mb-4">
            <div class="basis-5/6">
              <b>Smörstekt grön sparris</b>
              <br />
              inrullad i parmaskinka på salladsbädd med parmesanost
            </div>
          </div>
          <div class="flex flex-row mb-20 lg:mb-14">
            <div class="basis-9/12 lg:basis-10/12  border-b pb-4">
              <b>Oxfilé Tournedos</b>
              <br />
              med rödvinssås & parmaskinkachips
              <br />
              <em>alternativt</em>
              <br />
              <b>Hälleflundra</b>
              <br />
              med hummersås & kräftstjärtar
            </div>
            <div class="basis-3/12 lg:basis-1/12 text-right border-b pb-4">
              <br />
              <br />
              <br />
              <br />
              389
            </div>
          </div>

          <div class="mb-20 lg:mb-14">
            <div class="mb-6 text-2xl">FÖRRÄTTER</div>
            {[
              ["Vitlöksbröd", "med chiliaioli & grönsallad", "79"],
              [
                "Mozzarella Caprese",
                "buffelost med tomat, basilika, svartpeppar & olivolja",
                "99",
              ],
              ["Musslor", "gratinerade musslor med parmesan & vitlök", "119"],
              [
                "Toast Skagen",
                "med räkor, crème fraiche & majonnäs toppat med kräftstjärtar",
                "99",
              ],
              [
                "Smörstekt grön sparris",
                "inrullad i parmaskinka på salladsbädd med parmesanost",
                "119",
              ],
              ["Hummersoppa", "med kräftstjärtar & crème fraiche", "119"],
              [
                "Carpaccio",
                "tunnskivad oxfilé, ruccola, parmesanost, citron & olivolja",
                "169",
              ],
            ].map(([title, subtitle, price]) => (
              <div class="flex flex-row mb-4">
                <div class="basis-9/12 lg:basis-10/12  border-b pb-4">
                  <b>{title}</b>
                  <br />
                  {subtitle}
                </div>
                <div class="basis-3/12 lg:basis-1/12 text-right border-b pb-4">
                  <br />
                  {price}
                </div>
              </div>
            ))}
          </div>

          <div class="mb-20 lg:mb-14">
            <div class="mb-6 text-2xl">KÖTTRÄTTER</div>
            {[
              ["Fläskfilémedaljonger", "med smörbrynta champinjoner, sparris & bearnaisesås", "219"],
              [
                "Pollo Mozzarella",
                "mozzarellagratinerad kycklingfilé med gorgonzolasås",
                "229",
              ],
              [
                "Filé Mignon Black & White",
                "oxfilé & fläskfilé med bearnaisesås & rödvinssås",
                "259",
              ],
              ["Entrecôte", "med rödvinssky & örtsmör", "279 "],
              [
                "La Cucina köttplanka",
                "oxfiléplanka med bearnaisesås & baconlindade haricots verts",
                "299",
              ],
              [
                "Oxfilé Oscar",
                "med bearnaisesås, rödvinssås, grön sparris & kräftstjärtar",
                "299",
              ],
              ["Pepparstek", "konjakflamberad oxfilé med pepparsås", "299"],
              ["Husets Tournedos", "oxfilé med gorgonzolasås", "299"],
              [
                "Lamm Provencale",
                "stekt lamm med råstekt potatis, grillade tomater, grön sparris & vitlökssås",
                "299",
              ],
              [
                "Oxfilé Provencale (minst 2 personer)",
                "heltstekt oxfilé med örtsmör, grillade tomater, råstekt potatis & baconlindade haricots verts",
                "299/pp",
              ],
            ].map(([title, subtitle, price]) => (
              <div class="flex flex-row mb-4">
                <div class="basis-9/12 lg:basis-10/12  border-b pb-4">
                  <b>{title}</b>
                  <br />
                  {subtitle}
                </div>
                <div class="basis-3/12 lg:basis-1/12 text-right border-b pb-4">
                  <br />
                  {price}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div class="basis-6/12">
          <div class="mb-20 lg:mb-14">
            <div class="mb-6 text-2xl">FISKRÄTTER</div>
            {[
              [
                "Smörstekt gösfilé",
                "med skirat smör, pepparrot, räkor & kräftstjärtar",
                "259",
              ],
              [
                "Scampi á la Tham",
                "sauterad scampi med vitt vin, tomatsås & grädde",
                "259",
              ],
              ["Halstrad laxfjäril", "med limesås", "249"],
              ["Hälleflundra", "med hummersås & kräftstjärtar", "299"],
              [
                "La Cucina fiskplanka",
                "stekta rödingfiléer på planka med  hummersås, grön sparris & kräftstjärtar",
                "289",
              ],
            ].map(([title, subtitle, price]) => (
              <div class="flex flex-row mb-4">
                <div class="basis-9/12 lg:basis-10/12  border-b pb-4">
                  <b>{title}</b>
                  <br />
                  {subtitle}
                </div>
                <div class="basis-3/12 lg:basis-1/12 text-right border-b pb-4">
                  <br />
                  {price}
                </div>
              </div>
            ))}
          </div>

          <div class="mb-20 lg:mb-14">
            <div class="mb-6 text-2xl">SALLADER</div>
            {[
              [
                "Insalata di Cesare",
                "ceasarsallad med grillad kyckling",
                "189",
              ],
              [
                "Insalata di Mare",
                "örtmarinerade skaldjur på salladsbädd med krämig citrondressing ",
                "189",
              ],
            ].map(([title, subtitle, price]) => (
              <div class="flex flex-row mb-4">
                <div class="basis-9/12 lg:basis-10/12  border-b pb-4">
                  <b>{title}</b>
                  <br />
                  {subtitle}
                </div>
                <div class="basis-3/12 lg:basis-1/12 text-right border-b pb-4">
                  <br />
                  {price}
                </div>
              </div>
            ))}
          </div>

          <div class="mb-20 lg:mb-14">
            <div class="mb-6 text-2xl">PASTARÄTTER</div>
            {[
              ["Pasta Primör", "tagliatelle med grönsaker & vitvinssås", "169"],
              [
                "Pasta Gorgonzola",
                "tagliatelle med fläskfilé, champinjoner & gorgonzolasås",
                "169",
              ],
              [
                "Pasta Limone",
                "tagliatelle med citron, räkor, kräftstjärtar, vitvinssås, ruccola & olivolja",
                "169",
              ],
              [
                "Pappardelle con Pollo",
                "bred bandpasta med kyckling, lök, tomatsås, grädde & parmesanost",
                "169",
              ],
              [
                "Pasta Frutti di Mare",
                "tagliatelle med scampi, räkor, musslor, vitt vin, vitlök, tomatsås & ruccola",
                "179",
              ],
              [
                "Pappardelle in Barca",
                "bred bandpasta med oxfilé, lök, zucchini, basilika, tomatsås, grädde & parmesanost",
                "189",
              ],
              [
                "Pappardelle con Carne",
                "bred bandpasta med oxfilé, champinjoner, parmaskinka, marsala, grädde & parmesanost",
                "189",
              ],
              [
                "Tortellini alla Fiorentina",
                "spenat- & ricottafylld tortellini med fläskfilé, champinjoner, spenat, grädde & parmesanost",
                "199",
              ],
            ].map(([title, subtitle, price]) => (
              <div class="flex flex-row mb-4">
                <div class="basis-9/12 lg:basis-10/12  border-b pb-4">
                  <b>{title}</b>
                  <br />
                  {subtitle}
                </div>
                <div class="basis-3/12 lg:basis-1/12 text-right border-b pb-4">
                  <br />
                  {price}
                </div>
              </div>
            ))}
          </div>
          <div class="mb-20 lg:mb-14">
            <div class="mb-6 text-2xl">BARNMENY</div>
            {[
              ["Hamburgare", "med pommes frites", "79"],
              ["Pasta Bolognese", "tagliatelle med köttfärssås", "79"],
              ["Fläskfilé", "med pommes frites & bearnaisesås", "129"],
            ].map(([title, subtitle, price]) => (
              <div class="flex flex-row mb-4">
                <div class="basis-9/12 lg:basis-10/12  border-b pb-4">
                  <b>{title}</b>
                  <br />
                  {subtitle}
                </div>
                <div class="basis-3/12 lg:basis-1/12 text-right border-b pb-4">
                  <br />
                  {price}
                </div>
              </div>
            ))}
          </div>

          <div class="mb-20 lg:mb-14">
            <div class="mb-6 text-2xl">DESSERTER</div>
            {[
              [
                "Vaniljglass",
                "med grädde & chokladsås alt. varm hjortronsylt",
                "79",
              ],
              ["Mangosorbet", "med frukt", "79"],
              ["Tiramisú", "italiensk likörtårta", "99"],
              [
                "Panna cotta",
                "italiensk puddingdessert serverad med bär",
                "89",
              ],
              ["Camembert", "friterad dessertost med varm hjortronsylt", "99"],
            ].map(([title, subtitle, price]) => (
              <div class="flex flex-row mb-4 ">
                <div class="basis-9/12 lg:basis-10/12  border-b pb-4">
                  <b>{title}</b>
                  <br />
                  {subtitle}
                </div>
                <div class="basis-3/12 lg:basis-1/12 text-right border-b pb-4">
                  <br />
                  {price}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}
